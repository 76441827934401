/* This CSS was added to fix missing styles. Republish your site to fix your styles. */
.education-item {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 12;
  grid-column-start: span 12;
  -ms-grid-column-span: 12;
  grid-column-end: span 12;
}

#w-node-_6d4ade57-b937-f780-b80c-0c79b1da0990-2a830c0e,
#w-node-_8d8e0eff-8d04-785f-c906-6c48769d43bb-769d43b7 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 12;
  grid-column-start: span 12;
  -ms-grid-column-span: 12;
  grid-column-end: span 12;
}
#w-node-_6d4ade57-b937-f780-b80c-0c79b1da0991-2a830c0e,
#w-node-_6d4ade57-b937-f780-b80c-0c79b1da09a0-2a830c0e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 6;
  grid-column-start: span 6;
  -ms-grid-column-span: 6;
  grid-column-end: span 6;
  -ms-grid-column-align: start;
  justify-self: start;
}
#w-node-_8e454688-028a-83b4-a215-b194917e0020-2a830c0e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 5;
  grid-column-start: span 5;
  -ms-grid-column-span: 5;
  grid-column-end: span 5;
}
#w-node-_8e454688-028a-83b4-a215-b194917e0034-2a830c0e {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-column-span: 6;
  grid-column-end: 13;
}
#w-node-_3d09b860-35ce-ae8a-aa2f-f1997cd803b8-2a830c0e,
#w-node-_8f4cb56c-adcc-b0bf-8f74-f664f4c28baf-f4c28b99,
#w-node-_8f4cb56c-adcc-b0bf-8f74-f664f4c28bba-f4c28b99 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}
#w-node-_3d09b860-35ce-ae8a-aa2f-f1997cd803ba-2a830c0e {
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-column: 4;
  grid-column-start: 4;
  -ms-grid-column-span: 3;
  grid-column-end: 7;
}
#w-node-_3d09b860-35ce-ae8a-aa2f-f1997cd803c1-2a830c0e {
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-column-span: 3;
  grid-column-end: 10;
}
#w-node-_3d09b860-35ce-ae8a-aa2f-f1997cd803c8-2a830c0e {
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-column: 10;
  grid-column-start: 10;
  -ms-grid-column-span: 3;
  grid-column-end: 13;
}
#w-node-_8f4cb56c-adcc-b0bf-8f74-f664f4c28b9d-f4c28b99 {
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 6;
  grid-column-end: 7;
}
#w-node-_8f4cb56c-adcc-b0bf-8f74-f664f4c28b9f-f4c28b99 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 6;
  grid-column-start: span 6;
  -ms-grid-column-span: 6;
  grid-column-end: span 6;
  -ms-grid-row-align: center;
  align-self: center;
}
#w-node-_8f4cb56c-adcc-b0bf-8f74-f664f4c28bc5-f4c28b99 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 6;
  grid-column-start: span 6;
  -ms-grid-column-span: 6;
  grid-column-end: span 6;
  -ms-grid-row-align: start;
  align-self: start;
}
#w-node-_8f4cb56c-adcc-b0bf-8f74-f664f4c28bca-f4c28b99 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 6;
  grid-column-start: span 6;
  -ms-grid-column-span: 6;
  grid-column-end: span 6;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}
#w-node-_8bdc9590-22a2-e972-4daa-ca5062e76206-62e76204 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: start;
  justify-self: start;
}
#w-node-_8bdc9590-22a2-e972-4daa-ca5062e76209-62e76204 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: end;
  justify-self: end;
}
@media screen and (max-width: 991px) {
  #w-node-_3d09b860-35ce-ae8a-aa2f-f1997cd803b8-2a830c0e,
  #w-node-_6d4ade57-b937-f780-b80c-0c79b1da0990-2a830c0e,
  #w-node-_8e454688-028a-83b4-a215-b194917e0034-2a830c0e,
  #w-node-_8f4cb56c-adcc-b0bf-8f74-f664f4c28b9d-f4c28b99 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 8;
    grid-column-start: span 8;
    -ms-grid-column-span: 8;
    grid-column-end: span 8;
  }
  #w-node-_6d4ade57-b937-f780-b80c-0c79b1da0991-2a830c0e,
  #w-node-_6d4ade57-b937-f780-b80c-0c79b1da09a0-2a830c0e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
    -ms-grid-column-align: start;
    justify-self: start;
  }
  #w-node-_3d09b860-35ce-ae8a-aa2f-f1997cd803ba-2a830c0e,
  #w-node-_3d09b860-35ce-ae8a-aa2f-f1997cd803c1-2a830c0e,
  #w-node-_3d09b860-35ce-ae8a-aa2f-f1997cd803c8-2a830c0e {
    -ms-grid-column: span 8;
    grid-column-start: span 8;
    -ms-grid-column-span: 8;
    grid-column-end: span 8;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
  #w-node-_8f4cb56c-adcc-b0bf-8f74-f664f4c28baf-f4c28b99,
  #w-node-_8f4cb56c-adcc-b0bf-8f74-f664f4c28bba-f4c28b99 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }
  #w-node-_8f4cb56c-adcc-b0bf-8f74-f664f4c28bc5-f4c28b99 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 8;
    grid-column-start: span 8;
    -ms-grid-column-span: 8;
    grid-column-end: span 8;
    -ms-grid-column-align: start;
    justify-self: start;
  }
  #w-node-_8f4cb56c-adcc-b0bf-8f74-f664f4c28bca-f4c28b99 {
    -ms-grid-column: span 8;
    grid-column-start: span 8;
    -ms-grid-column-span: 8;
    grid-column-end: span 8;
    -ms-grid-column-align: start;
    justify-self: start;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
}
@media screen and (max-width: 767px) {
  #w-node-_6d4ade57-b937-f780-b80c-0c79b1da0990-2a830c0e,
  #w-node-_8f4cb56c-adcc-b0bf-8f74-f664f4c28b9d-f4c28b99,
  #w-node-_8f4cb56c-adcc-b0bf-8f74-f664f4c28bc5-f4c28b99 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 6;
    grid-column-start: span 6;
    -ms-grid-column-span: 6;
    grid-column-end: span 6;
  }
  #w-node-_6d4ade57-b937-f780-b80c-0c79b1da0991-2a830c0e,
  #w-node-_6d4ade57-b937-f780-b80c-0c79b1da09a0-2a830c0e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 6;
    grid-column-start: span 6;
    -ms-grid-column-span: 6;
    grid-column-end: span 6;
    -ms-grid-column-align: start;
    justify-self: start;
  }
  #w-node-_3d09b860-35ce-ae8a-aa2f-f1997cd803b8-2a830c0e,
  #w-node-_3d09b860-35ce-ae8a-aa2f-f1997cd803ba-2a830c0e,
  #w-node-_3d09b860-35ce-ae8a-aa2f-f1997cd803c1-2a830c0e,
  #w-node-_3d09b860-35ce-ae8a-aa2f-f1997cd803c8-2a830c0e,
  #w-node-_8e454688-028a-83b4-a215-b194917e0034-2a830c0e,
  #w-node-_8f4cb56c-adcc-b0bf-8f74-f664f4c28bca-f4c28b99 {
    -ms-grid-column: span 6;
    grid-column-start: span 6;
    -ms-grid-column-span: 6;
    grid-column-end: span 6;
  }
}
@media screen and (max-width: 479px) {
  #w-node-_3d09b860-35ce-ae8a-aa2f-f1997cd803ba-2a830c0e,
  #w-node-_3d09b860-35ce-ae8a-aa2f-f1997cd803c1-2a830c0e,
  #w-node-_3d09b860-35ce-ae8a-aa2f-f1997cd803c8-2a830c0e,
  #w-node-_6d4ade57-b937-f780-b80c-0c79b1da0990-2a830c0e,
  #w-node-_6d4ade57-b937-f780-b80c-0c79b1da0991-2a830c0e,
  #w-node-_6d4ade57-b937-f780-b80c-0c79b1da09a0-2a830c0e,
  #w-node-_8e454688-028a-83b4-a215-b194917e0034-2a830c0e,
  #w-node-_8f4cb56c-adcc-b0bf-8f74-f664f4c28b9d-f4c28b99,
  #w-node-_8f4cb56c-adcc-b0bf-8f74-f664f4c28b9f-f4c28b99,
  #w-node-_8f4cb56c-adcc-b0bf-8f74-f664f4c28baf-f4c28b99,
  #w-node-_8f4cb56c-adcc-b0bf-8f74-f664f4c28bba-f4c28b99,
  #w-node-_8f4cb56c-adcc-b0bf-8f74-f664f4c28bc5-f4c28b99,
  #w-node-_8f4cb56c-adcc-b0bf-8f74-f664f4c28bca-f4c28b99 {
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }
  #w-node-_3d09b860-35ce-ae8a-aa2f-f1997cd803b8-2a830c0e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }
}
